import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Us</Subheading>}
        heading="Come see the world with us."
        buttonRounded={false}
        primaryButtonUrl="/"
        primaryButtonText="Explore"
        description="Dial & Travel was started in 1990 by Mr. Ashok Jain with a mission of helping clients travel across the Globe."
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Provide reliable and quality services to our customers."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
        primaryButtonUrl="https://goo.gl/maps/yR8En95ECuqFPYjM6"
        description="We liaison directly with International Destination management companies around the world; those have good reputation and heritage in the Industry. Thanks to our old and strong relationship with our suppliers, they do offer good service to our clients and of course, competitive rates.We have carefully selected them around the world."
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="We believe in treating our customers and members with respect and faith. We grow through creativity, invention and innovation. We integrate honesty, integrity and business ethics into all aspects of our business functioning."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Expertise Knowledge",
            description: "We work on your behalf to arrange best of the experiences/services as per your desire."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Service Quality",
            description: "Our client service delivery process is designed with an objective to provide maximum convenience and quality service to you."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Our goal is to become involved with your development and concerns, enabling us to take a consultative approach to our services."
          },
        ]}
        linkText=""
      />
      <TeamCardGrid
        subheading={<Subheading>Our Team</Subheading>}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
