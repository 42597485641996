import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
  cards = [
    {
      imageSrc: 'https://cdn-icons-png.flaticon.com/512/870/870194.png',
      title: "Air Ticketing",
      description: "We provide a one-stop solution to get the best deals not just from the airlines but also from all other segments of the travel business.",
      url: "/#"
    },
    {
      imageSrc: 'https://cdn-icons-png.flaticon.com/512/1475/1475996.png',
      title: "Hotel Booking",
      description: "We provide you hotel bookings all over the world with great deals. It offers everything from budget hotels, to luxury resorts and heritage properties, as well as 3 star, 4 star and 5 star hotels.",
      url: "/#"
    },
    {
      imageSrc: 'https://cdn-icons-png.flaticon.com/512/2627/2627036.png',
      title: "Holidays Planning",
      description: "Travel around the world with our range of exotic, affordable and luxurious holiday packages, from domestic holidays to international holidays, get the best rates on your preferred holiday destinations.",
      url: "/#"
    },
    {
      imageSrc: 'https://cdn-icons-png.flaticon.com/512/7058/7058467.png',
      title: "Visa Assistance",
      description: "Our visa agents are professional in giving visa consultation and assisting in visa queries. Whether it is a business visa, student visa, tourist visa or work visa, we have a deep experience in the domain. ",
      url: "/#"
    },
    {
      imageSrc: 'https://cdn-icons-png.flaticon.com/512/2209/2209673.png',
      title: "Travel Insurance",
      description: "We assist you in buying the best international and domestic travel insurance plans to have a safe and enjoyable trip and be protected against the unexpected events.",
      url: "/#"
    },
    {
      imageSrc: 'https://cdn-icons-png.flaticon.com/512/3490/3490884.png',
      title: "MICE",
      description: "We provides exclusive packages for MICE, Corporate Travels, Group Tour and Excursions at best price for the Corporates, Business Professionals, Families and Student Groups.",
      url: "/#"
    }
  ],
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
                {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
